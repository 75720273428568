import {
  FETCHING_DATA,
  GET_ADMIN_USER,
  GET_ADMIN_TPA_DATA,
  SAVE_ADMIN_TPA_DATA,
  SAVE_ADMIN_USER,
  GET_ADMIN_USER_TPA_DATA,
  DELETE_ADMIN_USER,
  CREATE_ADMIN_USER,
  SAVE_ADMIN_USER_TPA_DATA,
  GET_TPA_ID,
  CHECK_USER_IS_ADMIN,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const getAdminUsersActions = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_ADMIN_USER),
      },
    });
    await apiAction(dispatch, GET_ADMIN_USER);
  };
};

export const getTpaDataActions = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_ADMIN_TPA_DATA),
      },
    });
    await apiAction(dispatch, GET_ADMIN_TPA_DATA);
  };
};

export const saveTpaDataActions = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === SAVE_ADMIN_TPA_DATA),
      },
    });
    await apiAction(dispatch, SAVE_ADMIN_TPA_DATA, payload);
  };
};

export const saveAdminUsersActions = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === SAVE_ADMIN_USER),
      },
    });
    await apiAction(dispatch, SAVE_ADMIN_USER, payload);
  };
};

export const getUserTpaDataActions = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_ADMIN_USER_TPA_DATA),
      },
    });
    await apiAction(dispatch, GET_ADMIN_USER_TPA_DATA);
  };
};

export const deleteAdminUsersActions = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === DELETE_ADMIN_USER),
      },
    });
    await apiAction(dispatch, DELETE_ADMIN_USER, payload);
  };
};

export const createAdminUsersActions = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === CREATE_ADMIN_USER),
      },
    });
    await apiAction(dispatch, CREATE_ADMIN_USER, payload);
  };
};

export const saveUserTpaDataActions = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === SAVE_ADMIN_USER_TPA_DATA),
      },
    });
    await apiAction(dispatch, SAVE_ADMIN_USER_TPA_DATA, payload);
  };
};

export const getTpaIdsActions = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_TPA_ID),
      },
    });
    await apiAction(dispatch, GET_TPA_ID);
  };
};

export const checkUserIsAdminActions = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === CHECK_USER_IS_ADMIN),
      },
    });
    await apiAction(dispatch, CHECK_USER_IS_ADMIN);
  };
};
