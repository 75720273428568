export const TITLE = {
  FILE_TRACKING: 'File Tracking',
  INPUT_FILE_SEARCH_RESULTS: 'Input file search results',
  FILE_DETAILS: 'File details',
};

export const CLASS_NAME = {
  SEARCH_TITLE: 'searchtitle',
  LINE: 'line',
  FILE_TRACKING_GRID_CLASS: 'file-tracking-grid',
  FILE_DETAILS_GRID_CLASS: 'file-details-grid',
  FILE_TRACKING_GRID_STATUS_TEXT: 'file-tracking-grid-status-text',
  FILE_SEARCH_TITLE: 'file-search-title',
  FILE_DETAILS_DOWNLOAD: 'file-details-download',
  FILE_SEARCH_RESULT: 'file-details-search-result',
  FILE_TRACKING_RESULT: 'file-tracking-search-result',
  DATE_PICKER_CONTAINER: 'date-picker-container',
  DOWNLOAD_CONTAINER: 'download-container',
};

export const FILE_DETAILS_COLUMN = [
  {
    headerName: 'Date Received',
    field: 'dateReceived',
    minWidth: 100,
  },
  {
    headerName: 'Record Identifier',
    field: 'recordIdentifier',
    minWidth: 100,
  },
  {
    headerName: 'Group_ID Cigna Assigned',
    field: 'recordIdentifier',
    minWidth: 250,
  },
  {
    headerName: 'Group_ID Client Assigned',
    field: 'groupIDCliantAssigned',
    minWidth: 250,
  },
  {
    headerName: 'Primary Subscriber SSN',
    field: 'primarySubscriberSSN',
    minWidth: 250,
  },
  {
    headerName: 'Alternate Member ID_client assigned',
    field: 'alternateMemberIdClientAssigned',
    minWidth: 350,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    minWidth: 250,
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 250,
  },
  {
    headerName: 'Middle Initial',
    field: 'middleInitial',
    minWidth: 250,
  },
  {
    headerName: 'Suffix (Member Name)',
    field: 'suffix',
    minWidth: 250,
  },
  {
    headerName: 'Relationship to Employee',
    field: 'relationshipToEmployee',
    minWidth: 250,
  },
  {
    headerName: 'Legal Gender',
    field: 'legalGender',
    minWidth: 250,
  },
  {
    headerName: 'Date of Birth',
    field: 'dob',
    minWidth: 100,
  },
  {
    headerName: 'Address Line 1',
    field: 'address1',
    minWidth: 100,
  },
  {
    headerName: 'Address Line 2',
    field: 'address2',
    minWidth: 100,
  },
  {
    headerName: 'City',
    field: 'city',
    minWidth: 250,
  },
  {
    headerName: 'State',
    field: 'state',
    minWidth: 250,
  },
  {
    headerName: 'Zip Code',
    field: 'zipCode',
    minWidth: 250,
  },
  {
    headerName: 'Home Area Code and Phone Number',
    field: 'homeCodeAndPhoneNumber',
    minWidth: 350,
  },
  {
    headerName: 'Division or Location_Client assigned',
    field: 'divisionOrLocationClientAssigned',
    minWidth: 350,
  },
  {
    headerName: 'Medical Network Type',
    field: 'medicalNetworkType',
    minWidth: 250,
  },
  {
    headerName: 'Cigna Network Code',
    field: 'cignaNetworkCode',
    minWidth: 100,
  },
  {
    headerName: 'Client Specific Network Identifier',
    field: 'clientSpecificNetworkIdentifier',
    minWidth: 300,
  },
  {
    headerName: 'Effictive date of Medical Network coverage',
    field: 'effectiveDateMedicalNetworkCoverage',
    minWidth: 350,
  },
  {
    headerName: 'Termination date of Medical Network coverage',
    field: 'terminationDateMedicalNetworkCoverage',
    minWidth: 400,
  },
  {
    headerName: 'PBM Plan ID',
    field: 'PbmPlanId',
    minWidth: 250,
  },
  {
    headerName: 'Effective date of PBM coverage',
    field: 'effectiveDateOfPbmCoverage',
    minWidth: 350,
  },
  {
    headerName: 'Termination date of PBM coverage',
    field: 'terminationDateOfPbmCoverage',
    minWidth: 350,
  },
  {
    headerName: 'Dental Plan ID',
    field: 'dentalPlanId',
    minWidth: 250,
  },
  {
    headerName: 'Effective date of Dental coverage',
    field: 'effectiveDateofDentalcoverage',
    minWidth: 350,
  },
  {
    headerName: 'Termination date of Dental coverage',
    field: 'terminationDateofDentalcoverage',
    minWidth: 350,
  },
  {
    headerName: 'State of Situs',
    field: 'stateOfSitus',
    minWidth: 100,
  },
  {
    headerName: 'Grandfathering Indicator',
    field: 'grandfatheringIndicator',
    minWidth: 250,
  },
  {
    headerName: 'ERISA Status',
    field: 'erisaStatus',
    minWidth: 250,
  },
  {
    headerName: 'Funding Type',
    field: 'fundingType',
    minWidth: 250,
  },
  {
    headerName: 'Coverage Type',
    field: 'coverageType',
    minWidth: 250,
  },
  {
    headerName: 'Native Americal Indicator',
    field: 'nativeAmericanIndicator',
    minWidth: 250,
  },
  {
    headerName: 'Essential Health Benefit (EHB) State',
    field: 'essentialHealthBenifitState',
    minWidth: 350,
  },
  {
    headerName: 'Benefit Metal Level Code',
    field: 'benefitMetalLevelCode',
    minWidth: 300,
  },
  {
    headerName: 'Legal Entity',
    field: 'legalEntity',
    minWidth: 250,
  },
  {
    headerName: 'Foreign Language Requirement Indicator',
    field: 'foraignLanguageRequirementIndicator',
    minWidth: 400,
  },
  {
    headerName: 'Foreign Language as Primary Language',
    field: 'foreignLanguagePrimaryLanguage',
    minWidth: 400,
  },
  {
    headerName: 'Preferred Last Name',
    field: 'preferredLastName',
    minWidth: 250,
  },
  {
    headerName: 'Preferred First Name',
    field: 'preferredFirstName',
    minWidth: 250,
  },
  {
    headerName: 'Pronoun',
    field: 'pronoun',
    minWidth: 250,
  },
  {
    headerName: 'Gender Identify',
    field: 'genderIdentify',
    minWidth: 100,
  },
  {
    headerName: 'Alternate Address Line 1',
    field: 'alternateAddressLine1',
    minWidth: 300,
  },
  {
    headerName: 'Alternate Address Line 2',
    field: 'alternateAddressLine2',
    minWidth: 300,
  },
  {
    headerName: 'Alternate City',
    field: 'alternateCity',
    minWidth: 250,
  },
  {
    headerName: 'Alternate State',
    field: 'alternateState',
    minWidth: 250,
  },
  {
    headerName: 'Alternate Zip Code',
    field: 'alternateZipCode',
    minWidth: 250,
  },
  {
    headerName: 'Privacy Address Line 1',
    field: 'privacyAddressLine1',
    minWidth: 250,
  },
  {
    headerName: 'Privacy Address Line 2',
    field: 'privacyAddressLine2',
    minWidth: 250,
  },
  {
    headerName: 'Privacy Address City',
    field: 'privacyAddressCity',
    minWidth: 250,
  },
  {
    headerName: 'Privacy Address State',
    field: 'privacyAddressState',
    minWidth: 250,
  },
  {
    headerName: 'Privacy Address Zip Code',
    field: 'privacyAddressZipCode',
    minWidth: 250,
  },
  {
    headerName: 'Email Address',
    field: 'emailAddress',
    minWidth: 100,
  },
  {
    headerName: 'Dual Coverage',
    field: 'dualCoverage',
    minWidth: 100,
  },
  {
    headerName: 'COBRA indicator',
    field: 'cobraIndicator',
    minWidth: 100,
  },
  {
    headerName: 'Medicare Primary Indicator',
    field: 'medicarePrimaryIndicator',
    minWidth: 250,
  },
  {
    headerName: 'Filter',
    field: 'filter',
    minWidth: 250,
  },
];

export const PAGINATION = [10, 20, 50, 100, 500, 1000];

export const TEXT = {
  DOWNLOAD_RESULTS: 'Download Results',
  DOWNLOAD_EXCEL: 'Download Excel',
};

export const FILE_STATUS = {
  REJECTED: {
    TEXT: 'Rejected',
    CLASS: 'file-tracking-error',
  },
  COMPLETED: {
    TEXT: 'Completed',
    CLASS: 'file-tracking-success',
  },
  PROCESSING: {
    TEXT: 'Processing',
    CLASS: 'file-tracking-working',
  },
};
