import { HEADER_TEXT } from '../../../Constants/ComponentConstants/componentConstants';
import { PRIMARY_PERSON_NUMBER } from '../../../Constants/ComponentConstants/MembershipConstants/membershipConstants';
import { isObject, lowercaseFirstLetter } from '../../Common/common.util';
export const apiToFeMapping = (
  data = {},
  type = HEADER_TEXT?.SELECTED_USER?.PRIMARY,
) => {
  if (type === HEADER_TEXT?.SELECTED_USER?.PRIMARY) {
    return {
      ...data,
      subscriberInformation: {
        ...data.subscriberInformation,
        relationshipWithEmployee:
          data?.subscriberInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
      },
      complianceInformation: {
        ...data.complianceInformation,
        legalEntity: data?.complianceInformation?.legalEntity || '-',
      },
      additionalInformation: {
        ...data.additionalInformation,
        email: data?.additionalInformation?.email || '',
        cobraIndicator: data?.additionalInformation?.cobraIndicator || '',
        phoneNumber: data?.additionalInformation?.phoneNumber || '',
        medicarePrimaryIndicator:
          data?.additionalInformation?.medicarePrimaryIndicator || '',
        death_day: data?.additionalInformation?.death_day || '',
      },
    };
  } else {
    return {
      ...data,
      subscriber_information: {
        ...data.subscriber_information,
        relationshipWithEmployee:
          data?.subscriber_information?.relationshipWithEE,
        relationshipWithEE: undefined,
        personNumber: PRIMARY_PERSON_NUMBER,
        groupNumber: data?.subscriber_information?.relationshipWithEE || '',
      },
      membershipInformation: {
        ...data.membershipInformation,
        relationshipWithEmployee:
          data?.membershipInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
      },
      complianceInformation: {
        ...data.complianceInformation,
        legalEntity: data?.complianceInformation?.legalEntity || '',
      },
      primaryInformation: {
        ...data.primaryInformation,
        relationshipWithEmployee: data?.primaryInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
        suffix: data?.primaryInformation?.suffix || '',
        membershipId: data?.subscriber_information?.membershipId || '',
        alternateMemberId: data?.primaryInformation?.alternateMemberId || '',
      },
      additionalInformation: {
        ...data.additionalInformation,
        email: data?.additionalInformation?.email || '',
        cobraIndicator: data?.additionalInformation?.cobraIndicator || '',
        phoneNumber: data?.additionalInformation?.phoneNumber || '',
        medicarePrimaryIndicator:
          data?.additionalInformation?.medicarePrimaryIndicator || '',
        death_day: data?.additionalInformation?.death_day || '',
      },
    };
  }
};

export const getData = async (
  setShowLoading,
  selectedUser,
  getPrimarySubscriberData,
  getDependentData,
) => {
  setShowLoading(true);
  if (
    selectedUser?.type === HEADER_TEXT?.SELECTED_USER?.PRIMARY &&
    selectedUser?.personNumber === PRIMARY_PERSON_NUMBER
  )
    await getPrimarySubscriberData(selectedUser);
  else await getDependentData(selectedUser);
  setShowLoading(false);
};

const removeEmptyValue = (obj = {}) => {
  Object.keys(obj).forEach((ele) => {
    obj[lowercaseFirstLetter(ele)] = obj[ele];
    if (!obj[ele]) obj[ele] = '-';
    if (!obj[lowercaseFirstLetter(ele)]) obj[lowercaseFirstLetter(ele)] = '-';
  });
  return obj;
};

export const removeEmptyfromMembershipObj = (membership = {}) => {
  if (isObject(membership)) {
    Object.keys(membership).forEach((mem) => {
      if (isObject(membership[mem])) removeEmptyValue(membership[mem]);
    });
  }
  return membership;
};

export const getSubscriberAddresColumn = ({ SubscriberStreetAddress }) => {
  return [
    {
      headerName: 'Street Address',
      field: 'streetAddress',
      minWidth: 250,
      cellRenderer: SubscriberStreetAddress,
    },
    {
      headerName: 'City',
      minWidth: 200,
      field: 'city',
    },

    {
      headerName: 'State',
      minWidth: 150,
      field: 'state',
    },
    {
      headerName: 'Zip Code',
      minWidth: 150,
      field: 'postalCode',
    },
  ];
};
