import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import "../../../Assets/Css/BasicSearchScreen.css"
import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { emptyObject } from "../../../Utils/Common/common.util"
import { ROUTES } from "../../../Constants/routesConstant"
import Icon from "../../Common/Icon"
import CustomModal from '../../Common/CustomModal';

const AdvancedSearchScreen = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = { groupId: '', membershipId: '', personNumber: '', firstName: '', lastName: '', birthDate: '', gender: '', city: '', state: '', postalCode: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [errorMsg, setErrorMsg] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const today = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value, type } = e.target;
      let newValue = value;
       const alphaOnlyFields = ['textInput']; 
    const alphanumericFields = ['mixedInput']; 
      if (alphaOnlyFields.includes(name) && type === 'text') {
           newValue = value.replace(/[0-9]/g, '');
        }
      if (type === 'number') {
      const maxLength = 12;
      let numericValue = value.replace(/[^0-9]/g, '');  
           newValue = numericValue.slice(0, maxLength); 
        }
      if (alphanumericFields.includes(name) && type === 'text') {
          newValue = value;
       }
        setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
        }));
       };
       const handleDateChange = (event) => {
        const inputDate = event.target.value;
        if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
          const year = parseInt(inputDate.split("-")[0], 10);
          if (year < 1900 || inputDate > today) {
            alert("Please select the valid date");
            return false;
          } else {
            const { name, value } = event.target;
            setFormData(prevData => ({
              ...prevData,
              [name]: value
            }));
            return true;
          }
        }
      };
    
  const handleHelp = () => {
    setOpenPopup(true);
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
  };
  const combinations = [
    "lastName, firstName",
    "lastName, firstName, Date of Birth",
    "lastName, firstName, postalCode",
    "lastName, firstName, Date of Birth, postalCode",
    "lastName, firstName, Date of Birth, postalCode, gender",
    "lastName, firstName, Date of Birth, membershipId",
    "lastName, firstName, Date of Birth, postalCode, gender, membershipId",
    "lastName:startsWith, Date of Birth",
    "lastName:startsWith",
    "lastName:startsWith, firstName:startsWith",
    "lastName:startsWith, firstName:startsWith, Date of Birth",
    "lastName:startsWith, firstName:startsWith, postalCode",
    "lastName:startsWith, firstName:startsWith, state",
    "lastName:startsWith, firstName:startsWith, city, state",
    "lastName:startsWith, firstName:startsWith, Date of Birth, postalCode",
    "membershipId, groupId",
    "membershipId",
    "membershipId, groupId, personNumber",
    "membershipId, Date of Birth",
  ];
  const prepareContent = (combinations) => {
    return (
      <ul style={{listStyleType:'none'}} >
        {combinations.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const content = prepareContent(combinations);

  const handleSearch = async (e, param={}) => {
    e.preventDefault();
    const { firstName, lastName, birthDate, groupId, membershipId, personNumber, gender, city, state, postalCode } = formData;

    const advancedSearchdValidCombinations = [
      ['lastName'],
      ['firstName', 'lastName'],
      ['firstName', 'lastName', 'Date of Birth'],
      ['firstName', 'lastName', 'postalCode'],
      ['firstName', 'lastName', 'Date of Birth', 'postalCode'],
      ['firstName', 'lastName', 'Date of Birth', 'postalCode', 'gender'],
      ['firstName', 'lastName', 'Date of Birth', 'membershipId'],
      ['firstName', 'lastName', 'Date of Birth', 'postalCode', 'gender', 'membershipId'],
      ['lastName:startsWith', 'Date of Birth'],
      ['lastName:startsWith'],
      ['lastName:startsWith', 'firstName:startsWith'],
      ['lastName:startsWith', 'firstName:startsWith', 'Date of Birth'],
      ['lastName:startsWith', 'firstName:startsWith', 'postalCode'],
      ['lastName:startsWith', 'firstName:startsWith', 'state'],
      ['lastName:startsWith', 'firstName:startsWith', 'city', 'state'],
      ['lastName:startsWith', 'firstName:startsWith', 'Date of Birth', 'postalCode'],
      ['membershipId', 'groupId'],
      ['membershipId'],
      ['membershipId', 'Date of Birth'],
      ['membershipId', 'groupId', 'personNumber']
    ];
    function isValidCombination(providedParams) {
      const result = advancedSearchdValidCombinations.some(combination =>
        combination.length === providedParams.length &&
        combination.every(field => providedParams.includes(field))
      );
      return result;
    }
    const providedParams = Object.keys(formData).filter(key => formData[key] !== '');

    if (!isValidCombination(providedParams)) {
      alert("Please click on Help icon in the Eligibility search widget to know valid combinations that has to be entered for getting search results");
           return;
    }
    props.input({ ...props.component_input, 'search': formData })
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (birthDate) {
        searchQuery['birthDate'] = birthDate;
      }
      if (groupId) {
        searchQuery['groupId'] = groupId;
      }
      if (membershipId) {
        searchQuery['membershipId'] = membershipId;
      }
      if (personNumber) {
        searchQuery['personNumber'] = personNumber;
      }
      if (city) {
        searchQuery['city'] = city;
      }
      if (state) {
        searchQuery['state'] = state;
      }
      if (postalCode) {
        searchQuery['postalCode'] = postalCode;
      }
      if (gender) {
        searchQuery['gender'] = gender;
      }
      await props.getSearchData(searchQuery)
        navigate(`${ROUTES.SUBSCRIBER.SEARCH_RESULT}`, { ...param, state: { from: location?.pathname } })
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const handleKeyPress = (e) => {
    const { type } = e.target;
      if (type === 'text' && /[0-9]/.test(e.key)) {
      e.preventDefault(); 
    }
    if (e.key === 'Enter') {
     e.preventDefault();
      handleSearch(e);
    }
  }
  
  return (
    <div className="search-form">      
      <Typography variant="h5" gutterBottom="true" sx={{color:  "#035c67", fontWeight: '600'}}>
        Eligibility Advanced Search
        <Icon name="InfoIcon" onClick={handleHelp} className="adv-help-icon" />
      </Typography>
      <Typography variant="body2" gutterBottom="true" sx={{marginBottom: 2}}>
        To view an Alpha list of participants, enter a partial last name followed by an astrick, or followed by a partial last name and partial first name followed by an astrick . Entry of a groupid is required
        to limit the search criteria. Organization Id and Comp(?) may also be entered to further limit the search criteria.
       
      </Typography>
      <div className="first-group">
        <div className="form-group">
          <b>Cigna Group ID</b>
          <input
            type="number"
            name="groupId"
            value={formData.groupId}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div >
        <div className="form-group">
          <b>Membership ID</b>
          <input
            use type="number"
            name="membershipId"
            value={formData.membershipId}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div>
        <div className="form-group">
          <b>Person Number</b>
          <input
            use type="number"
            name="personNumber"
            value={formData.personNumber}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div>
      </div>
      <div className="second-group">
        <div className="form-group">
          <b>First Name</b>
          <input
            id="textInput"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>Last Name</b>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>Date of Birth</b>
          <input
            type="date"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleChange}
            onBlur={handleDateChange}
            onKeyDown={(e) => handleKeyPress(e)}
            min="1900-01-01"
            max={today}
          />
        </div>
        <div className="form-group">
          <b>Gender</b>
          <input
            type="text"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>City</b>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>State</b>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>Postal Code</b>
          <input
            use type="number"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            maxLength={9}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 9 digits</small>
        </div>
      </div>
      <hr className="line" />
      <div className="button-group">
        <button className="greentext" onClick={handleReset}>Reset</button>
        <button type="submit" className="greenfilled" onClick={handleSearch}>Search</button>
      </div>
      <div className="errmsg">
        {errorMsg && (
          <div className="errmsg" id="errormsgs">
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsg}
            </Alert>
          </div>
        )}
      </div>
      <CustomModal
        open={openPopup}
        handleClose={handlePopupClose}
        title="Advanced Search Combinations"
        content={content}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return { ...state };
};
const mapActionToProps = {
  input: inputAction,
  getSearchData: membershipSearchAction
};
export default connect(mapStateToProps, mapActionToProps)(AdvancedSearchScreen);