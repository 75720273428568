import React from "react";
import {isArray} from "../../../Utils/Common/common.util"
import BreakLineAddress from "./BreakLineAddress"


const SubscriberStreetAddress = ({ value = '' }) => {
    return <>
        {isArray(value) ? <BreakLineAddress value={value} /> : value}
    </>
}
export default SubscriberStreetAddress