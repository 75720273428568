const OKTA_SCOPES = [
  'openid',
  'email',
  'profile',
  'esrx.default',
  'offline_access',
];

const ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowX: 'auto',
};

const COMMON_CLASS = {
  AG_THEME_ALPINE: 'ag-theme-alpine',
  CURSOR_POINTER: 'cursor-pointer',
  ML_30: 'ml-30',
};

const COMMON_TEXT = {
  CUSTOM: 'custom',
};

const AG_COLUMN_CENTER = {
  headerClass: 'text-center',
  cellStyle: {
    textAlign: 'center',
  },
};

const CAPS_TO_SPACE = ['DOB', 'SSN'];

const REPLACE_WORD = {
  Tpa: 'Payer',
  DOB: 'Date of Birth',
  Ssn: 'SSN',
  Id: 'ID',
};

export {
  OKTA_SCOPES,
  ACTIVITY_EVENTS,
  modalStyle,
  COMMON_CLASS,
  COMMON_TEXT,
  AG_COLUMN_CENTER,
  CAPS_TO_SPACE,
  REPLACE_WORD,
};
