import React from 'react';
import { capitalizeFirstLetter } from "../../../Utils/Common/common.util"
import { getStatusClassName, getStatus } from "../../../Utils/Widget/PayerEligibilityFileTracking/fileTracking.util"
import {
    CLASS_NAME,
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"

const FileStatusRender = ({ value = '', node: { key = '' }, rowData = [] }) => {
    const {
        FILE_TRACKING_GRID_STATUS_TEXT
    } = CLASS_NAME
    return (
        <>
            {
                !value ?
                    <div className={`${getStatusClassName(capitalizeFirstLetter(getStatus(rowData, key)))}`}>
                        <span className={FILE_TRACKING_GRID_STATUS_TEXT}>{capitalizeFirstLetter(getStatus(rowData, key))}</span>
                    </div> :
                    <div className={`${getStatusClassName(capitalizeFirstLetter(value))}`}>
                        <span className={FILE_TRACKING_GRID_STATUS_TEXT}>{capitalizeFirstLetter(value)}</span>
                    </div>
            }
        </>
    )
}

export default FileStatusRender