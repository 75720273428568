import React, {useState, useEffect, useRef} from "react";

const InputCellRenderer = (params) => {
    const {id} = params?.data;
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, []);

    const handleUserField = (e, params) => {
        const value = e.target.value;
        if (value.length >= 0 && value.length <= 7) {
            params.setValue(e.target.value)
        }
        return;
    }

    const handleKeyDown = (e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            e.stopPropagation();
        }
    }
    
    const showValidation = (!params?.value || params?.value.trim() === '')
    const showUserInput = params?.value?.length >= 5 && params?.value?.length <= 7;

    // useEffect(() => {
    //     console.log('id in render->', id);
    //     params?.handleLanValidation({lanId: id, lanIdError: showValidation ? showValidation :  !showUserInput});
    // }, []);

    useEffect(() => {
        params?.handleLanValidation({lanId: id, lanIdError: showValidation ? showValidation :  !showUserInput});
    }, [showValidation, showUserInput]);
    
    if (params.isEditable) {
        return (<div style={{ width: '100%', height: '100%'}}>
            <input 
                ref={inputRef}
                type="text"
                value={params.value || ''}
                onChange={(e) => handleUserField(e, params)}
                onKeyDown={handleKeyDown}
                style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
            />
            {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User ID</div>}
            {!showUserInput && <div style={{color: 'red', fontSize: '12px'}}>User ID Should be Min 5 and less then 7 in length</div>}
        </div>)
    } else {
        return (<>{params.data.User}</>)
    }
}
export default InputCellRenderer