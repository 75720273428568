import { ELIGIBILITY_ERROR_TRENDS_API } from '../../../Constants/actionTypes';

export const eligibilityErrorTrendsReducer = (state = null, action) => {
  switch (action.type) {
    case ELIGIBILITY_ERROR_TRENDS_API:
      return action.payload;
    default:
      return state;
  }
};
