import React from "react"
import Icon from "../../Common/Icon"

const HelpfulLinks = (props) => {
    const { links } = props;
    return (<>
        {
            links.map(link => {
                return (
                    <p key={`${JSON.stringify(link)}-${props.currIndex}`} id={`admin-security-setup-p-${props.currIndex}`}>
                        <a id={`admin-security-setup-a-${props.currIndex}`}
                            className={`widget-anchor`}
                            href="#"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textDecoration: 'none',
                                fontSize: '1rem',
                                lineHeight: '1.5rem',
                                maxWidth: '100%',
                            }}

                        >
                            <span style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                flex: '1'


                            }} title={link.text}>
                                {link.text}
                            </span>
                            <Icon name="ArrowOutwardIcon" />
                        </a>
                    </p>)
            })
        }
    </>)
}

export default HelpfulLinks