const SELECTED_USER = 'SELECTED_USER';
const ALL_USERS = 'ALL_USERS';
const COMPONENT_INPUT = 'COMPONENT_INPUT';
const SAVE_OKTA_TOKEN = 'SAVE_OKTA_TOKEN';
const MEMBERSHIP_API = 'MEMBERSHIP_API';
const DEPENDENT_API = 'DEPENDENT_API';
const MEMBERSHIP_SEARCH_API = 'MEMBERSHIP_SEARCH_API';
const TOKEN_GENERATION_API = 'TOKEN_GENERATION_API';
const TOKEN_VERIFY_API = 'TOKEN_VERIFY_API';
const API_ERR = 'API_ERR';
const FETCHING_DATA = 'FETCHING_DATA';
const GET_DASHBOARD_SETTINGS = 'GET_DASHBOARD_SETTINGS';
const SAVE_DASHBOARD_SETTINGS = 'SAVE_DASHBOARD_SETTINGS';
const GET_ADMIN_USER = 'GET_ADMIN_USER';
const GET_ADMIN_TPA_DATA = 'GET_ADMIN_TPA_DATA';
const SAVE_ADMIN_TPA_DATA = 'SAVE_ADMIN_TPA_DATA';
const SAVE_ADMIN_USER = 'SAVE_ADMIN_USER';
const GET_ADMIN_USER_TPA_DATA = 'GET_ADMIN_USER_TPA_DATA';
const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
const CREATE_ADMIN_USER = 'CREATE_ADMIN_USER';
const ERROR_TRENDS_MTD_API = 'ERROR_TRENDS_MTD_API';
const ELIGIBILITY_ERRORS_MTD_API = 'ELIGIBILITY_ERRORS_MTD_API';
const ELIGIBILITY_ERRORSREJ_MTD_API = 'ELIGIBILITY_ERRORSREJ_MTD_API';
const PAYER_FILE_RATIO_MTD_API = 'PAYER_FILE_RATIO_MTD_API';
const SAVE_ADMIN_USER_TPA_DATA = 'SAVE_ADMIN_USER_TPA_DATA';
const GET_MEMBER_PRODUCTS = 'GET_MEMBER_PRODUCTS';
const CHECK_USER_IS_ADMIN = 'CHECK_USER_IS_ADMIN';
const ASSIGNEE_API = 'ASSIGNEE_API';
const ELIGIBILITY_ERROR_RESULTS_API = 'ELIGIBILITY_ERROR_RESULTS_API';
const FILE_TRACKING_WIDGET = 'FILE_TRACKING_WIDGET';
const FILE_TRACKING = 'FILE_TRACKING';
const GET_TPA_ID = 'GET_TPA_ID';
const FILE_NAME_SEARCH = 'FILE_NAME_SEARCH';
const FILE_SEARCH = 'FILE_SEARCH';
const TEAM_MEMBERS = 'TEAM_MEMBERS';
const MARK_AS_COMPLETE = 'MARK_AS_COMPLETE';
const ERROR_RESULTS_MTD = 'ERROR_RESULTS_MTD';
const REASSIGN_ALL_TO_ME = 'REASSIGN_ALL_TO_ME';
const ELIGIBILITY_ERROR_TRENDS_API = 'ELIGIBILITY_ERROR_TRENDS_API';

export {
  SELECTED_USER,
  ALL_USERS,
  COMPONENT_INPUT,
  SAVE_OKTA_TOKEN,
  MEMBERSHIP_API,
  DEPENDENT_API,
  MEMBERSHIP_SEARCH_API,
  TOKEN_GENERATION_API,
  TOKEN_VERIFY_API,
  API_ERR,
  FETCHING_DATA,
  GET_DASHBOARD_SETTINGS,
  SAVE_DASHBOARD_SETTINGS,
  GET_ADMIN_USER,
  GET_ADMIN_TPA_DATA,
  SAVE_ADMIN_TPA_DATA,
  SAVE_ADMIN_USER,
  GET_ADMIN_USER_TPA_DATA,
  DELETE_ADMIN_USER,
  CREATE_ADMIN_USER,
  ERROR_TRENDS_MTD_API,
  ELIGIBILITY_ERRORS_MTD_API,
  ELIGIBILITY_ERRORSREJ_MTD_API,
  PAYER_FILE_RATIO_MTD_API,
  SAVE_ADMIN_USER_TPA_DATA,
  GET_MEMBER_PRODUCTS,
  CHECK_USER_IS_ADMIN,
  ASSIGNEE_API,
  ELIGIBILITY_ERROR_RESULTS_API,
  FILE_TRACKING_WIDGET,
  FILE_TRACKING,
  GET_TPA_ID,
  FILE_NAME_SEARCH,
  FILE_SEARCH,
  ELIGIBILITY_ERROR_TRENDS_API,
  REASSIGN_ALL_TO_ME,
  TEAM_MEMBERS,
  MARK_AS_COMPLETE,
  ERROR_RESULTS_MTD,
};
