import {
  FETCHING_DATA,
  MARK_AS_COMPLETE,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const markAsCompleteAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === MARK_AS_COMPLETE),
      },
    });

    await apiAction(dispatch, MARK_AS_COMPLETE, payload);
  };
};
