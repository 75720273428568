import * as React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Box} from "@mui/material";
import AdminSettings from "./AdminSettings/AdminSettings";
import SecuritySettings from './SecuritySettings/SecuritySettings';
import {ADMIN_SECURITY} from "../../Constants/ComponentConstants/componentConstants"
import {a11yProps} from "../../Utils/AdminAndSecurity/adminSecurity.util"
import '../../Assets/Css/admin.css';


export const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props; 

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminAndSecurity = () => {
  const [value, setValue] = React.useState(0);
  const {TABS_HEADER} = ADMIN_SECURITY
  const {ADMIN_SETTINGS, SECURITY_SETTINGS} = TABS_HEADER

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '90vw', minHeight: '67vh', marginTop: '-50px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div style={{ padding: '20px', margin: '30px', fontSize: '24px', fontWeight: '600',marginLeft: '20px', }}>Admin &amp; security setup</div>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',marginLeft:'50px',display: 'inline-block' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={ADMIN_SETTINGS} {...a11yProps(0)} />
          <Tab label={SECURITY_SETTINGS} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AdminSettings/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SecuritySettings/>
      </CustomTabPanel>
    </Box>
  );
}
export default AdminAndSecurity