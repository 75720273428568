import React from "react"
import Icon from "../../Common/Icon"

const GotoFileDetails = ({goToFile, value, icon, fileClass}) => {
    return (
      <>
        {
          value ?
            <Icon
              className={fileClass}
              onClick={() => goToFile(value)}
              name={icon}
            /> :
            <></>
        }
      </>
    )
  }


  export default GotoFileDetails