import Icon from "../../Common/Icon"

const DeleteButtonRenderer = (params) => {    
    const {data, newRowData} = params;
    return (<>
        <Icon name="DeleteIcon" 
            onClick={() => params.handleDelete({data, newRowData})}
        />
    </>)
}

export default DeleteButtonRenderer