import {
  FILE_TRACKING_WIDGET,
  FILE_TRACKING,
  FETCHING_DATA,
  FILE_SEARCH,
  FILE_NAME_SEARCH,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const fileTrackingWidgetAction = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === FILE_TRACKING_WIDGET),
      },
    });
    await apiAction(dispatch, FILE_TRACKING_WIDGET);
  };
};

export const fileTrackingAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === FILE_TRACKING),
      },
    });
    await apiAction(dispatch, FILE_TRACKING, payload);
  };
};

export const fileSearchAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === FILE_SEARCH),
      },
    });
    await apiAction(dispatch, FILE_SEARCH, payload);
  };
};

export const fileNameSearchAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === FILE_NAME_SEARCH),
      },
    });
    await apiAction(dispatch, FILE_NAME_SEARCH, payload);
  };
};
