import React from "react"
import { Grid } from "@mui/material";
import Icon from "../../Common/Icon"
import '../../../Assets/Css/Dashboard.css';
import { ROUTES } from "../../../Constants/routesConstant"
import "../../../Assets/Css/Breadcrumbs.css"
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { fileTrackingWidgetAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { v4 as uuidv4 } from 'uuid';
const PayerEligibilityFileTracking = (props) => {
    const navigate = useNavigate()
    const { useEffect } = React
    const { getData, file_tracking_widget: { data } } = props;
    const { FILE_TRACKING } = ROUTES
    useEffect(() => {
        getData()
    }, [])

    return (<>
        <Icon className="dashboard-icons" />
        <Grid item className={`error-container `} xs={10}>Files recieved by Payer</Grid>
        {data?.map(({ payerName = '', fileCount = '' }) => {
            return (
                <Grid
                    onClick={() => navigate(`${FILE_TRACKING}`, { state: { payerName } })}
                    container key={`${uuidv4()}-file-tracking`}>
                    <Grid item className={`error-container widget-anchor common-line`} xs={10}>
                        <span
                            className={`widget-anchor`} title={payerName}>
                            {payerName}
                        </span>
                    </Grid>
                    <Grid item className={`text-right error-container common-line`} xs={2}>{fileCount}</Grid>
                </Grid>
            )
        })}
             <Grid item className={`text-right error-container common-line border-buttom`} xs={2}></Grid>

    </>)
}

const mapStateToProps = (state) => {
    const { file_tracking_widget } = state
    return { file_tracking_widget };
};

const mapActionToProps = {
    getData: fileTrackingWidgetAction
};

export default connect(mapStateToProps, mapActionToProps)(PayerEligibilityFileTracking);
