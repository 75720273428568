import React from "react"
import { LinearProgress, Box, Grid, Button, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { eligibilityErrorsMTDAction } from '../../../Redux/Actions/Widgets/eligibilityErrorsMTDAction';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../../../Assets/Css/BasicSearchScreen.css';
import '../../../Assets/Css/EligibilityErrors.css';
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate, useLocation } from "react-router-dom";
import widgetConstant, { MTD_ITEM } from '../../../Constants/widgetConstant';
import "../../../Assets/Css/Breadcrumbs.css"
import { eligibilityErrorResultsAction } from '../../../Redux/Actions/Errorflow/eligibilityErrorResultsAction';

ChartJS.register(ArcElement, Tooltip, Legend);
const EligibilityErrors = (props) => {
  const [datas, setDatas] = React.useState('');
  const { totalError, getErrorResultsMTDData, eligerr_mtddata } = props;
  const [links, setLinks] = React.useState([]);
  const range = widgetConstant[props.componentName].mtdItem ? widgetConstant[props.componentName].mtdItem : MTD_ITEM[4];
  const rangeStr = String(range);
  const objtoarr = (obj) => {
    const tempLink = [];
    if (!obj || !obj.payerTrends)
      return;
    obj.payerTrends.forEach(elm => {
      if (!tempLink.map(txt => txt.text).includes(elm.tpaName)) {
        tempLink.push({
          text: elm.tpaName,
          value: elm
        });
      }
    });
    setLinks([...tempLink]);
  };
  React.useEffect(() => {
    getErrorResultsMTDData({ range: props.mtdItem === "" ? MTD_ITEM[4] : props.mtdItem })
  }, [props.mtdItem]);
  React.useEffect(() => {
    objtoarr(eligerr_mtddata);
    if (obj.payerTrends?.length > 0) {
      setDatas(obj.payerTrends[0]?.tpaName);
    }
  }, [eligerr_mtddata]);

  const obj = eligerr_mtddata;
  let data = [
    {
      value: 48,
      color: "#1976d2",
      cutout: "80%",
    },
    {
      value: 24,
      color: "#F08080",
      cutout: "80%",
    },
    {
      value: 12,
      color: "#008000",
      cutout: "80%",
    },
    {
      value: 14,
      color: "#D3D3D3",
      cutout: "80%",
    },
  ]

  const options = {
    plugins: {
      responsive: true,
    },
    cutout: data.map((item) => item.cutout),
    radius: '80%',
    layout: {
      padding: 0
    }
  };

  const finalData = {
    datasets: [
      {
        data: obj?.payerTrends?.find(item => item.tpaName === datas)
          ? Object.entries(obj.payerTrends.find(item => item.tpaName === datas))
            .filter(([key]) => !['tpaName', 'totalErrors', 'backEndErrors'].includes(key))
            .map(([key, value]) => Math.round(parseInt(value)))
          : [],
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };
  const handleChange = (event) => {
    setDatas(event.target.value);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleViewAllErrors = async (param = {}) => {
    await props.errorResultsData({ range: range, viewAll: 1 });
    navigate(`${ROUTES.ERROR_RESULTS.replace(':range', rangeStr)}`, { ...param, state: { from: location?.pathname } })
  };
  const handleMyErrors = async (param = {}) => {
    props.errorResultsData({ range: range, viewAll: 0 });
    navigate(`${ROUTES.ERROR_RESULTS.replace(':range', rangeStr)}`, { ...param, state: { from: location?.pathname } })
  };
  if (obj?.payerTrends && obj?.payerTrends?.length === 0) {
    <p>No Data Available</p>
  }
  return (<>

    <div className={`eligibility-error-ratio`}>
      <div className={`eligibility-error-doughnut`}>
        <Doughnut data={finalData} options={options} />
      </div>
      <div className={`eligibility-error-count`}>
        {links.filter(item => item.text === datas || '').map(item => {
          return (
            <Typography
              variant="h4"
              align="left"
              whiteSpace="nowrap"
            >
              {item.value.totalErrors}
            </Typography>);
        })}
        <Typography
          variant="h5"
          align="left"
        >total</Typography>
      </div>
    </div>
    {links.filter(item => item.text === datas).map(item => {
      const { frontEndErrors, backEndErrors } = item.value;
      const maxValue = 100;
      const getBarWidth = (value) => {
        const percentage = parseFloat(value.split('(')[1].replace('%)', ''));
        return isNaN(percentage) ? 0 : percentage;
      }
      const totalWidth = getBarWidth(frontEndErrors) + getBarWidth(backEndErrors);
      return (
        <Box sx={{ width: '100%', display: 'flex', padding: 0, textWrap: 'nowrap' }}>
          <Box sx={{ width: `${(getBarWidth(frontEndErrors) / totalWidth) * 100}%`, padding: 0 }}>
            <Typography variant="caption">{getBarWidth(frontEndErrors)}%</Typography>
            <LinearProgress
              variant="determinate"
              value={0}
              sx={{
                width: '100%',
                backgroundColor: '#1976d2',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#1976d2'
                }
              }}
            />
            <Typography variant="body2">FE</Typography>
          </Box>
          <Box sx={{ width: `${(getBarWidth(backEndErrors) / totalWidth) * 100}%`, padding: 0 }}>
            <Typography variant="caption">{getBarWidth(backEndErrors)}%</Typography>
            <LinearProgress
              variant="determinate"
              value={0}
              sx={{
                width: '100%',
                backgroundColor: '#4caf50',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4caf50'
                }
              }}
            />
            <Typography variant="body2">BE</Typography>
          </Box>
        </Box>
      );
    })}
    <Typography
      align="left"
      gutterBottom="true"
      sx={{ marginTop: '10px', fontSize: '15px' }}
    >Select by Payer</Typography>
    <Box sx={{ minWidth: 120 }}>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Payers</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={datas}
          label="Payers"
          onChange={handleChange}
          size="small"
          MenuProps={{ PaperProps: { sx: { fontSize: '5px' }, }, }}
        >
          {links.map((key) => (
            <MenuItem key={key.text} value={key.text}>
              {key?.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </Box>
    {links.filter(item => item.text === datas || '').map(item => (
      <Grid container>
        <Grid item className="error-containers" xs={9}>
          <a href="#" className="frontend-errors"
          >
            Front-end errors</a>
        </Grid>
        <Grid item className="text-rights" xs={3}> {item?.value?.frontEndErrors || 0}</Grid>
        <Grid item className="error-containers" xs={9}>
          <a href="#" className="unassigned-backend-errors"
          >
            Unassigned back-end errors</a>
        </Grid>
        <Grid item className="text-rights" xs={3}>{item?.value?.unassigned || 0}</Grid>
        <Grid item className="error-containers" xs={9}>
          <a href="#" className="assigned-backend-errors"
          >
            Assigned back-end errors</a>
        </Grid>
        <Grid item className="text-rights" xs={3}> {item?.value?.assigned || 0}</Grid>
        <Grid item className="error-containers" xs={9}>
          <a href="#" className="completed-errors"
          >
            Completed errors</a>
        </Grid>
        <Grid item className="text-rights" xs={3}> {item?.value?.completed || 0}</Grid>

      </Grid>
    ))
    }


    <Grid item xs={12} className="eligibility-error-btn">
      <Button className={`eligibility-search-btn`} variant="text" size="small" onClick={handleViewAllErrors}>
        View all errors
          </Button>
      <Button className={`view-my-errors`} variant="contained" color="success" size="small" onClick={handleMyErrors}>
        View my errors
          </Button>
    </Grid>
  </>)
}
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  getErrorResultsMTDData: eligibilityErrorsMTDAction,
  errorResultsData: eligibilityErrorResultsAction,
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrors);