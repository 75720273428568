import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";
import { ROUTES, HIDE_LEFT_MENU_ROUTES, DENIED_PATH } from "../../Constants/routesConstant"
import { HEADER_TEXT, MORE_ACTION } from "../../Constants/ComponentConstants/componentConstants"
import { isValidJSON, ScrollToTop } from "../../Utils/Common/common.util"
import DependentModal from "../Membership/Details/DependentModal"
import IconButton from '@mui/material/IconButton';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { selectedUserAction } from "../../Redux/Actions/Membership/selectedUserAction"
import { oktaTokenAction } from "../../Redux/Actions/Token/oktaTokenAction"
import Logo from "../../Assets/Images/evernorth-logo-hypermint.svg";
import Icon from "../Common/Icon"
import ProductModal from "../Membership/Details/ProductModal"
import { checkUserIsAdminActions } from "../../Redux/Actions/Admin/adminAction"
import { EXCLUDE_WIDGET, ADMIN_SECURITY_SETUP_WIDGET } from "../../Constants/widgetConstant"
import "../../Assets/Css/header.css"
import Breadcrumbs from "./Breadcrumbs";
import Trackingmodal from '../Membership/Details/Trackingmodal';
import mockTrackingData from '../Membership/data/mockTrackingData';

const Header = (props) => {
  const { TEXT, MORE_ACTIONS_ROUTES, MENU } = MORE_ACTION
  const { SHOW_DEPENDENT,
    SHOW_PRIMARY_SUBSCRIBER,
    VIEW_AUDIT_HISTORY,
    ADD_DEPENDENT,
    EDIT
  } = MENU
  const { SUBSCRIBER, ACCESS_DENIED, ADMIN_SECURITY } = ROUTES
  const [openMembershipDependent, setOpenMembershipDependent] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAction, setShowAction] = useState(false)
  const [hideLeftMenu, setHideLeftMenu] = useState(false)
  const [openMembershipProduct, setOpenMembershipProduct] = useState(false)
  const [openTrackingInformation, setOpenTrackingInformation] = useState(false);
  const {
    doSetUser,
    selectedUser,
    membership,
    saveOktaToken,
    checkUserIsAdmin,
    check_admin
  } = props
  const { isAdmin = false } = check_admin
  const navigate = useNavigate()
  const location = useLocation()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDGCNavigation = () => {
    const dgcUrlEN = process.env.REACT_APP_DGC_URL;
    window.open(dgcUrlEN, '_blank');
  }

  const setDependent = (dependent) => {
    doSetUser({
      ...dependent,
      memberResourceId: selectedUser?.resourceId,
      dependentResourceId: dependent?.resourceId,
      dependentRelativeId: dependent?.resourceId,
      primaryUser: selectedUser,
    })
    navigate(SUBSCRIBER.DEPENDENT_DETAILS)
  }
  const setPrimary = () => {
    if (selectedUser?.primaryUser && selectedUser?.primaryUser?.resourceId) {
      doSetUser({ ...selectedUser.primaryUser })
      navigate(SUBSCRIBER.SUBSCRIBER_DETAILS)
    }
  }
  useEffect(() => {
    if (sessionStorage['okta-token-storage'] && isValidJSON(sessionStorage['okta-token-storage']))
      saveOktaToken(JSON.parse(sessionStorage['okta-token-storage'])?.accessToken)
  }, [sessionStorage['okta-token-storage']])

  useEffect(() => {
    checkUserIsAdmin()
  }, [])
  useEffect(() => {
    ScrollToTop()
    const deniedAdminIndex = DENIED_PATH.indexOf(ADMIN_SECURITY)
    const adminAndSecuritySetupWidgetIndex = EXCLUDE_WIDGET.indexOf(ADMIN_SECURITY_SETUP_WIDGET)
    if (isAdmin) {
      if (deniedAdminIndex > -1) DENIED_PATH.splice(deniedAdminIndex, 1)
      if (adminAndSecuritySetupWidgetIndex > -1) EXCLUDE_WIDGET.splice(adminAndSecuritySetupWidgetIndex, 1)
    }
    else {
      if (deniedAdminIndex === -1) DENIED_PATH.push(ADMIN_SECURITY)
      if (adminAndSecuritySetupWidgetIndex === -1) EXCLUDE_WIDGET.push(ADMIN_SECURITY_SETUP_WIDGET)
    }

    if (DENIED_PATH.includes(location.pathname)) navigate(ACCESS_DENIED)
    if (MORE_ACTIONS_ROUTES.indexOf(location.pathname) > -1) setShowAction(true)
    else setShowAction(false)

    if (HIDE_LEFT_MENU_ROUTES.indexOf(location.pathname) > -1) setHideLeftMenu(true)
    else setHideLeftMenu(false)
  }, [location, check_admin])
  return (
    <>
      <div
        className="container header-container"
        item
        xs={12}
      >
        <img src={Logo} alt="header-logo" height={30} />
      </div>
      <>
        {
          !hideLeftMenu ?
            <div className='left-menu-container'>
              <IconButton className='left-menu-item' onClick={() => navigate(ROUTES.DASHBOARD)} ><p>{HEADER_TEXT.RETURN_TO_DASHBOARD}</p><Icon name="KeyboardReturnIcon" /></IconButton>
              <IconButton className='left-menu-item' onClick={() => window.location.reload()} ><p>{HEADER_TEXT.REFRESH}</p><Icon name="RefreshIcon" /></IconButton>
              {showAction ? <><IconButton className='left-menu-item' >
                <p id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>{TEXT}</p>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="header-menu"
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div >
                    <div className="iconContainer">
                      <Icon name="PeopleOutlinedIcon" className="icon" />
                      <MenuItem className="header-list">
                        {selectedUser.type === HEADER_TEXT.SELECTED_USER.PRIMARY ?
                          <span onClick={() => { handleClose(); setOpenMembershipDependent(true); }}>{SHOW_DEPENDENT}</span> :
                          <span onClick={() => { handleClose(); setPrimary(); }} >{SHOW_PRIMARY_SUBSCRIBER}</span>}
                      </MenuItem>
                    </div>
                    <div className="iconContainer">
                      <Icon name="ArticleOutlinedIcon" className="icon" />
                      <MenuItem onClick={() => { handleClose(); setOpenTrackingInformation(true); }}>{VIEW_AUDIT_HISTORY}</MenuItem>
                    </div>
                    {selectedUser.type === HEADER_TEXT.SELECTED_USER.PRIMARY && <div className="iconContainer">
                      <Icon name="PeopleOutlinedIcon" className="icon" />
                      <MenuItem onClick={() => { handleDGCNavigation(); }}>{ADD_DEPENDENT}</MenuItem>
                    </div>}
                    <div className="iconContainer">
                      <Icon name="EditIcon" className="icon" />
                      <MenuItem onClick={() => { handleDGCNavigation(); }}>{EDIT}</MenuItem>
                    </div>
                  </div>
                </Menu>
              </IconButton>
                <DependentModal
                  setDependent={setDependent}
                  open={openMembershipDependent}
                  close={setOpenMembershipDependent}
                  dependent={membership.dependents}
                />
                <ProductModal
                  open={openMembershipProduct}
                  close={setOpenMembershipProduct}
                />
                {openTrackingInformation && (
                  <Trackingmodal
                    open={openTrackingInformation}
                    close={() => setOpenTrackingInformation(false)}
                    trackingInfo={mockTrackingData}
                  />
                )}
              </> : <></>}
            </div>
            :
            <></>
        }
      </>
      <Breadcrumbs />
    </>
  );
};
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  doSetUser: selectedUserAction,
  saveOktaToken: oktaTokenAction,
  checkUserIsAdmin: checkUserIsAdminActions
};

export default connect(mapStateToProps, mapActionToProps)(Header);