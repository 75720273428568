import {
  FETCHING_DATA,
  REASSIGN_ALL_TO_ME,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const reAssignAllToMeAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === REASSIGN_ALL_TO_ME),
      },
    });

    await apiAction(dispatch, REASSIGN_ALL_TO_ME, payload);
  };
};
