import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Modal, Box, Typography, IconButton } from '@mui/material';
import Icon from "../Common/Icon"
import "../../Assets/Css/BasicSearchScreen.css";
import "../../Assets/Css/EligibilityErrorTrends.css"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '80%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 2,
  lineHeight:1,
};


const CustomModal = ({ open, handleClose, title, content, onConfirmComplete, showButtons, viewList, onItemClick, modalPosition, applyStyle }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        {!viewList && <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Icon name="CloseIcon" />
        </IconButton>}
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: ' #035c67' }}>
          {title}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <p applystyle  className = {applyStyle?"complete-content" : ''}>
          {typeof content === 'function' ? content() : content}
          </p>
          {viewList && viewList.length > 0 && (
            <List>
              {viewList.map((item, index) => (
                <ListItem button key={index}
                  onClick={() => { onItemClick(item) }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          )}
          {showButtons && (
            <div className="button-group">
              <button className={`view-all-error`} onClick={onConfirmComplete}  >ok</button>
              <button onClick={handleClose}>Cancel</button>
            </div>
          )}
        </Box>
      </Box>

    </Modal>
  );
};

export default CustomModal;