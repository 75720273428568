import {
  FILE_TRACKING_WIDGET,
  FILE_TRACKING,
  FILE_SEARCH,
  FILE_NAME_SEARCH,
} from '../../../Constants/actionTypes';

export const fileTrackingWidgetReducer = (state = null, action) => {
  switch (action.type) {
    case FILE_TRACKING_WIDGET:
      return action.payload;
    default:
      return state;
  }
};

export const fileTrackingReducer = (state = null, action) => {
  switch (action.type) {
    case FILE_TRACKING:
      return action.payload;
    default:
      return state;
  }
};

export const fileDetailsReducer = (state = null, action) => {
  switch (action.type) {
    case FILE_SEARCH:
      return action.payload;
    case FILE_NAME_SEARCH:
      return action.payload;
    default:
      return state;
  }
};
