import { emptyObject, getArrayPosition } from '../Common/common.util';
export const refineWidgetList = (widgets = []) => {
  return widgets.reduce((accu, widget) => {
    if (!emptyObject(widget) && widget?.component) accu.push(widget);
    return accu;
  }, []);
};

export const getDashboardWidget = (dashboard_settings) => {
  const { widgets } = dashboard_settings;
  return (widgets || [])?.map((widget) => {
    const { name, position } = widget;
    return { component: name, x: position?.x, y: position?.y };
  });
};

export const getDashboardSavePayload = (okta_token, refineActiveWidget) => {
  const { claims } = okta_token;
  return {
    cid: claims.cid,
    userId: claims.lanID,
    layout: {
      grid: { columns: 0, gutter: 0 },
      widgets: refineActiveWidget.map((eachWidget) => {
        const { component = '', x = 0, y = 0 } = eachWidget;
        return {
          name: component,
          position: {
            x: x,
            y: y,
          },
          size: {
            width: 0,
            height: 0,
          },
        };
      }),
    },
  };
};

const getDestinationIndex = (elmX, elmY, rowsHeight, columnWidth) => {
  // console.log("x--->", elmX)
  // console.log("Y--->", elmY)
  const elmCol = getArrayPosition(columnWidth, elmX);
  // console.log("dest column --->", elmCol)
  const elmRow = getArrayPosition(rowsHeight[elmCol], elmY);
  // console.log("dest row -->", elmRow)
  if (elmRow === -1 || elmCol === -1) return false;
  return (elmRow < 0 ? 0 : elmRow) * 3 + (elmCol < 0 ? 0 : elmCol);
};

export const setDropItem = (dropElm) => {
  const {
    e,
    draggedElement,
    item,
    mouseLocation,
    currentIndex,
    rowsHeight,
    columnWidth,
  } = dropElm;

  if (mouseLocation.position === 'inactive-widget') {
    // console.log("inactive-widget")
    // console.log(elm)
    if (draggedElement.component)
      item.push({ ...draggedElement, x: e.pageX, y: e.pageY });
  } else {
    let destinationIndex = 0;
    if (currentIndex === mouseLocation.index) {
      // console.log("RTL")
      const elmX = e.pageX;
      const elmY = e.pageY;
      destinationIndex = getDestinationIndex(
        elmX,
        elmY,
        rowsHeight,
        columnWidth,
      );
      // console.log("destination index --->", destinationIndex)
    }
    const dropIndex =
      mouseLocation.index === currentIndex
        ? destinationIndex
        : mouseLocation.index;
    // console.log("dropindex1 --->", dropIndex)
    // console.log("drop comp --->", item[dropIndex]?.component)
    // console.log("current comp --->", item[currentIndex]?.component)
    if (
      dropIndex < -1 ||
      !item[dropIndex]?.component ||
      !item[currentIndex]?.component
    )
      return;
    // console.log("currentIndex--->", currentIndex)
    // console.log("dropIndex--->", dropIndex)
    const dropItem = item[dropIndex];
    item[dropIndex] = draggedElement;
    item[currentIndex] = dropItem;
  }
  return item;
};

export const removeWidget = (data, widArr) => {
  const { currIndex = -1 } = data;
  if (currIndex < 0 || widArr.length < currIndex) return widArr;
  return widArr?.reduce((acc, elm, i) => {
    if (i !== currIndex) acc.push(elm);
    return acc;
  }, []);
};
export const getInactiveWidgetList = (
  inactiveWidgetsList,
  activeWidgetsList,
  WIDGET_LIST,
) => {
  WIDGET_LIST.forEach((Widget) => {
    if (activeWidgetsList.indexOf(Widget) === -1) {
      inactiveWidgetsList.push({ component: Widget });
    }
  });
  return inactiveWidgetsList;
};

export const setHeight = (
  arr,
  index,
  height,
  adjHeightbyCSS = 0,
  increaseZoomLevel,
) => {
  if (arr[index])
    arr[index].push(
      arr[index][arr[index].length - 1] +
        height +
        adjHeightbyCSS +
        (height + adjHeightbyCSS) * increaseZoomLevel,
    );
  else
    arr[index] = [
      height + adjHeightbyCSS + (height + adjHeightbyCSS) * increaseZoomLevel,
    ];
  return arr;
};

export const calculateEachRowHeight = (
  activeWidgetsList,
  increaseZoomLevel,
  setRowHeight,
) => {
  let heights = [];
  setRowHeight([]);
  activeWidgetsList.forEach((e, i) => {
    const eleId = `active-widget-${i}-grid`;
    const eleHeight = document.getElementById(eleId)?.clientHeight;
    if (i % 3 === 0) {
      heights = setHeight(heights, 0, eleHeight, 40, increaseZoomLevel);
    } else if (i % 3 === 1) {
      heights = setHeight(heights, 1, eleHeight, 40, increaseZoomLevel);
    } else if (i % 3 === 2) {
      heights = setHeight(heights, 2, eleHeight, 40, increaseZoomLevel);
    }
  });
  setRowHeight(heights.length > 0 ? [...[], ...heights] : []);
};
