import { SELECTED_USER } from '../../../Constants/actionTypes';
import { PRIMARY_PERSON_NUMBER } from '../../../Constants/ComponentConstants/MembershipConstants/membershipConstants';
import { HEADER_TEXT } from '../../../Constants/ComponentConstants/componentConstants';
export const selectedUserAction = (user) => {
  if (user?.personNumber === PRIMARY_PERSON_NUMBER)
    user['type'] = HEADER_TEXT?.SELECTED_USER?.PRIMARY;
  else user['type'] = HEADER_TEXT?.SELECTED_USER?.DEPENDENT;
  return (dispatch) => {
    dispatch({
      type: SELECTED_USER,
      payload: { user: user },
    });
  };
};
