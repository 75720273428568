import {
  FILE_TRACKING_WIDGET,
  FILE_TRACKING,
  FILE_NAME_SEARCH,
  FILE_SEARCH,
} from '../../../actionTypes';

import API_TYPE from '../../../apiMethodConstant';
import BODY_TYPE from '../../../apiBodyDefination';

const fileTrackingApiConstant = [
  {
    NAME: FILE_TRACKING_WIDGET,
    PATH: 'filetracking/filetrackingwidget',
    METHOD: API_TYPE.GET,
    AFTER_ACTION: FILE_TRACKING_WIDGET,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: FILE_TRACKING,
    PATH: 'filetracking/filetracker',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: FILE_TRACKING,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: FILE_NAME_SEARCH,
    PATH: 'filetracking/filenamesearch',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: FILE_NAME_SEARCH,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
  {
    NAME: FILE_SEARCH,
    PATH: 'filetracking/filerecordsearch',
    METHOD: API_TYPE.POST,
    AFTER_ACTION: FILE_SEARCH,
    RESPONSE: {},
    DATA: {},
    BODY_TYPE: BODY_TYPE.RAW,
    VERSION: 1,
  },
];

export default fileTrackingApiConstant;
