export const ROUTES = {
  DASHBOARD: '/',
  SUBSCRIBER: {
    SUBSCRIBER_DETAILS: '/subscriber/details',
    DEPENDENT_DETAILS: '/dependent/details',
    SEARCH_RESULT: '/subscriber/search',
  },
  AUTHORIZATION_CALLBACK: '/authorization-code/callback',
  SEARCH: '/searchscreen',
  ERROR_TRENDS: '/dashboard/error-trends/:range',
  ERROR_RESULTS: '/dashboard/error-results/:range',
  MY_ERROR_RESULTS: '/dashboard/my-error-results/:range',
  FILE_TRACKING: '/dashboard/file-tracking',
  FILE_SEARCH: '/dashboard/file-search',
  ADMIN_SECURITY: '/admin',
  NOT_FOUND: '*',
  ACCESS_DENIED: '/access-denied',
  ERROR_DETAILS: '/error-details/:id',
};

export const DENIED_PATH = [];

export const HIDE_LEFT_MENU_ROUTES = [ROUTES.DASHBOARD];
