import React from "react"
import Icon from "../../Common/Icon"


const EligibilityMaintenance = (props) => {
    const { links } = props;
    const handleDGCNavigation = () => {
        const dgcUrlEN = process.env.REACT_APP_DGC_URL;
        window.open(dgcUrlEN, '_blank');
      }
    return (<>
        {
            links.map(link => {
                return (
                    <p key={JSON.stringify(link)} id={`admin-security-setup-p-${props.currIndex}`}>
                        <a id={`admin-security-setup-a-${props.currIndex}`}
                            className={`widget-anchor`} href="#"style={{cursor:'pointer'}} >
                            {link.text}
                            <Icon name="ArrowOutwardIcon" onClick={handleDGCNavigation} style={{cursor:'pointer'}}/>
                        </a>
                    </p>)
            })
        }
    </>)
}

export default EligibilityMaintenance;