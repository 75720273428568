import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../Assets/Css/EligibilityErrorTrends.css"
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Icon from "../../Common/Icon";
import IconButton from '@mui/material/IconButton';
import { eligibilityErrorResultsAction } from '../../../Redux/Actions/Errorflow/eligibilityErrorResultsAction';
import { reAssignAllToMeAction } from '../../../Redux/Actions/Errorflow/reAssignAllToMeAction';
import { markAsCompleteAction } from '../../../Redux/Actions/Errorflow/markAsCompleteAction';
import { Box, Grid } from '@mui/material';
import CustomModal from '../../Common/CustomModal';
import Model from '../../Common/Model';
import { ROUTES } from "../../../Constants/routesConstant"
import { MTD_ITEM } from '../../../Constants/widgetConstant';
import { useParams } from 'react-router-dom';


const EligibilityErrorResults = (props) => {
    const { range } = useParams();
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [ranges, setRanges] = useState(range);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showColumnMenu, setShowColumnMenu] = useState(false);
    const [selectedItem, setSelectedItem] = useState(range);
    const [selectedName, setSelectedName] = useState(range);
    const [openPopup, setOpenPopup] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [membersModal, setMembersModal] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [teamMembersList, setTeamMembersList] = useState(['john', 'smith', 'steppello', 'Leslie knope', 'Amy poehler'])
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [content, setContent] = useState('');
    const [actionHandler, setActionHandler] = useState(null);
    const [isAssigned, setIsAssigned] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [refreshflag, setRefreshFlag] = useState(false);
    const [paramss, setParamss] = useState(null);
    const location = useLocation();
    const searchParams = location.pathname.split('/');
    const filter = searchParams[2];
    const gridRef = useRef(null);
    const gridApiRef = useRef(null);
    const columnMenuRef = useRef(null);
    const [modalPosition, setModalPosition] = useState({ top: '50%', left: '50%' });
    const [viewOptions, setViewOptions] = useState(['Assign to a team member', 'Assign to me', 'Mark as Complete']);
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const assignButtonDisabled = filter === 'my-error-results';
    const isButtonDisabled = filter === 'error-results';
    const { eligerror_result } = props;
   

    const CustomButtonComponent = (params, idparam) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <button
                style={{ color: params.data.errorType === "B" ? 'blue' : 'grey', border: 'none', cursor: params.data.errorType === "B" ? 'pointer' : '', background: 'transparent' }}
                onClick={() => handleDGCNavigation(params)}
            >
                Edit
        </button> */}

            <button
                style={{ color: 'blue', border: 'none', cursor: 'pointer', background: 'transparent', marginLeft: '10px' }}
                onClick={() => handleErrorDetails(params)}
            >
                View
        </button>

            <span
                style={{
                    color: params.data.errorType === "B" ? 'blue' : 'grey',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    marginLeft: '5px',
                    cursor: params.data.errorType === "B" ? 'pointer' : '',
                }}
                onClick={(e) => handleViewModal(params, event)}
            >
                ...
        </span>
        </div>
    };
    const [columnDefs, setcolumnDefs] = useState([
        { headerName: 'Actions', field: 'Button', cellRenderer: CustomButtonComponent, flex: 1, filter: false, sortable: false },
        {
            headerName: 'TPA Org ID', field: 'tpaOrgId', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: 'TPA Name', field: 'tpaName', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: ' Cigna Group ID', field: 'cignaGroupId', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        },
        {
            headerName: 'First name', field: 'firstName', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Last name', field: 'lastName', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'SSN', field: 'SSN', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
            filterParams: {
                applyMiniFilterWhileTyping: true,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                closeOnApply: true,
                buttons: ['reset', 'apply'],
            }
        },
        {
            headerName: 'DOB', field: 'birthDate', sortable: true, order: 5,
            comparator: (valueA, valueB) => {
                const dateA = new Date(valueA);
                const dateB = new Date(valueB);
                return dateA - dateB;
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Error Type', field: 'errorType', sortable: true, order: 2, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Error or Warning Description', field: 'errorMessage', sortable: true, order: 2, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Status', field: 'errorStatus', sortable: true, order: 1, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Assignee', field: 'assigneeName', sortable: true, order: 1, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
    ]);
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        gridRef.current = params.api;
        params.api.addEventListener('columnMoved', onColumnMoved);
        const savedColumnOrder = JSON.parse(localStorage.getItem('columnOrder'));
        if (savedColumnOrder) {
            const newcolumnDefs = savedColumnOrder.map(field =>
                columnDefs.find(colDef => colDef.field === field)
            );
            if (params.api.setcolumnDefs) {
                params.api.setcolumnDefs(newcolumnDefs);
            } else {
                console.error("setcolumnDefs is not available on params.api");
            }
        }
    };
    useEffect(() => {
        if (filter === "my-error-results") {
            props.errorResultsData({ range: ranges, viewAll: 0 });
        }
        if (filter === "error-results") {
            props.errorResultsData({ range: ranges, viewAll: 1 });
        }
    }, [ranges, props.mark_as_complete, props.reassign_all_to_me]);
    useEffect(() => {
        return () => {
            setRowData([])
        };
    }, []);
    useEffect(() => {
        if (gridApi && gridColumnApi) {
            gridColumnApi.getAllColumns().forEach((column) => {
            });
        }
        function handleClickOutside(event) {
            if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
                setShowColumnMenu(false);
            }
        }
        setRowData(props?.eligerror_result);

        const result = props?.eligerror_result;
        const flattenedData = result?.flatMap(item => {
            return item?.errors?.length > 0
                ? item.errors.map(error => ({
                    id: item._id,
                    tpaOrgId: item.tpaOrgId,
                    tpaName: item.tpaName,
                    cignaGroupId: item.cignaGroupId,
                    assigneeName: error.assigneeName,
                    errorType: error.errorType,
                    errorId: error._id,
                    errorMessage: error.errorMessage,
                    errorStatus: error.errorStatus,
                    SSN: item.SSN,
                    phoneNumber: item.phoneNumber,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    birthDate: item.birthDate,
                }))
                : [{
                    id: item._id,
                    tpaOrgId: item.tpaOrgId,
                    tpaName: item.tpaName,
                    cignaGroupId: item.cignaGroupId,
                    assigneeName: item.assigneeName,
                    errorType: item.errorType,
                    errorId: item._id,
                    errorMessage: item.errorMessage,
                    errorStatus: item.errorStatus,
                    SSN: item.SSN,
                    phoneNumber: item.phoneNumber,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    birthDate: item.birthDate,
                }]
        });

        setRowData(flattenedData);
        if (filter === 'my-error-results') {
            setRowData(flattenedData);
        } else {
            setRowData(flattenedData);
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, [gridApi, gridColumnApi, filter, props?.eligerror_result, refreshflag]);
    const handleItemClick = async (itemId) => {
        setSelectedItem(itemId);
        setRanges(itemId);
        setSelectedName(itemId);
        setDropDownVisible(false);
    };
    const handleClick = (e) => {
        setDropDownVisible(!dropDownVisible);
    };

    const handleViewModal = (params, event, rowStatus) => {
        setParamss(params);
        const updatedOptions = viewOptions.map((option) => {
            const isreassign = params.data.errorStatus === "Assigned" && option === "Assign to a team member";

            params.data.errorStatus === "Assigned" && option === "Assign to a team member "
            if (isreassign) {
                return "ReAssign to Team Member";
            }
            return option;
        });

        setViewOptions(updatedOptions);

        if (params.data.errorType === "B") {
            setViewModal(true);
        }
        setSelectedRow(params.node);
        setSelectedRow(params.node);
        const buttonPosition = event.target.getBoundingClientRect();
        const topPosition = buttonPosition.top + window.scrollY + 90;
        const leftPosition = buttonPosition.left + window.scrollX + 200;
        const adjustedTop = topPosition < 0 ? 0 : topPosition;
        const adjustedLeft = leftPosition < 0 ? 0 : leftPosition;
        setModalPosition({
            top: `${adjustedTop}px`,
            left: `${adjustedLeft}px`,
        });
    }
    const isAssignedInStatus = rowData.some((row) => row.erroStatus === 'Assigned');
    const isCompleteInStatus = rowData.some((row) => row.erroStatus === 'Complete');
    const handleErrorDetails = (params, idparam) => {
        navigate(`${ROUTES.ERROR_DETAILS.replace(':id', params.data.id)}`)
    };
    const handleDGCNavigation = (params) => {
        const dgcUrlEN = process.env.REACT_APP_DGC_URL;
        if (params.data.errorType === "B") {
            window.open(dgcUrlEN, '_blank');
        }
    };
    const handleApply = () => {
        setShowColumnMenu(false);
    };
    const handleReset = () => {
        const updatedColumns = columnDefs.map(column => ({
            ...column,
            hide: false
        }));
        setcolumnDefs(updatedColumns);
        setShowColumnMenu(false);
    };
    useEffect(() => {
        if (gridApi && gridColumnApi) {
            gridColumnApi.getAllColumns().forEach((column) => {
            });
        }
        function handleClickOutside(event) {
            if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
                setShowColumnMenu(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [gridApi, gridColumnApi]);
    const onColumnMoved = () => {
        if (gridRef.current) {
            const allColumnState = gridRef.current.getColumnState();
            const newColumnOrder = allColumnState.map(columnState => columnState.colId);
        }
    };
    const toggleColumnMenu = () => {
        setShowColumnMenu(!showColumnMenu);
    };
    const toggleColumn = (columnName, checked) => {
        const newcolumnDefs = columnDefs.map(column => {
            if (column.field === columnName) {
                return { ...column, hide: !checked };
            }
            return column;
        });
        setcolumnDefs(newcolumnDefs);
    };
    const gridOptions = {
        columnDefs: columnDefs,
        suppressBorders: true,
        defaultColDef: {
            minWidth: 150,
        },
    };
    const exportDataGridToExcel = (columnDefs, datas, fileName) => {
        const headers = columnDefs.filter(col => col.field !== 'Button').map(col => col.headerName);
        const mappedData = datas.map(row => {
            let mappedRow = {};
            columnDefs.forEach(col => {
                if (col.field !== 'Button') {
                    mappedRow[col.headerName] = row[col.field]
                }
            });
            return mappedRow;
        });
        const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, fileName);
    };
    const handleExportExcel = () => {
        const rowModel = gridApi.getModel();
        const filteredRows = [];
        rowModel.rowsToDisplay.forEach(node => {
            if (node.displayed) {

                filteredRows.push(node.data);
            }
        });


        const visibleColumns = columnDefs.filter(col => !col.hide);
        const visibleColumnFields = visibleColumns.map(col => col.field);
        const filteredData = filteredRows.map(row => {
            const filteredRow = {};
            visibleColumnFields.forEach(field => {
                if (field !== 'Button') {
                    filteredRow[field] = row[field];
                }
            });
            return filteredRow;
        })

        exportDataGridToExcel(visibleColumns, filteredData, 'Error Results.xlsx');
    };
    const handlePopupOpen = (type) => {
        setOpenPopup(true);
        if (type === 'assign') {
            setContent('In current view including all pages all incomplete and Un Assigned  errors will be assigned to your name, If you wish to continue please click "OK" to assign all errors to your Name. If you want to Re assign click on 3 dots at the VIEW button in each row.');
            setActionHandler(() => handleAssignToMe);
        } else if (type === 'complete') {
            setContent('This Action is permenant and cannot be reversed.Do you wish to continue?  If you want to update entire status column  as "Complete", Please Click "OK" to continue.');
            setActionHandler(() => handleComplete);
        }
    };
    ///403error/////
    const handleAssignToMe = async () => {

        setContent('Assignee content');
        await props.assignAllToMe();
        const response = props.reassign_all_to_me;
        const updatedData = rowData.map((row) => {
            if (row.errorType === 'B') {
                return {
                    ...row,
                    assigneeName: 'Pravallika'
                };
            }
            return row;
        });
        setRowData(updatedData);
        setIsAssigned(true);
        setOpenPopup(false);
    }
    //////403 error///

    const handleComplete = async () => {
        const filteredIds = rowData
            .filter((row) => row.errorType === "B" && row.errorStatus === "Assigned")
            .map((row) => row.id);

        await props.markAllAsComplete({
            "errorIds": filteredIds
        });
        setOpenPopup(false);
    }

    const handlePopupClose = () => {
        setOpenPopup(false);
    };
    const handleViewModalClose = () => {
        setViewModal(false);
        setMembersModal(false);
    };
    const handleViewItemClick = async (item) => {
        // setParamss(params);

        if (item === 'Assign to a team member') {
            setMembersModal(true);
        }

        if (item === 'Assign to me') {
            selectedRow.setDataValue('assigneeName', 'pravallika');
            setViewModal(false);
        }

        if (item === 'Mark as Complete') {
            await props.markAllAsComplete({
                "errorIds": paramss.data.errorId
            });
            setViewModal(false);
        }

    };
    useEffect(() => {

    }, [paramss]);

    const handleNameClick = (name) => {
        setSelectedMember(name)
        if (selectedRow) {
            selectedRow.setDataValue('assigneeName', selectedMember);
        }
        setMembersModal(false);
        setViewModal(false);
    }
    const handleContent = () => {
        return <div className="complete-content">This Action is permenant and cannot be reversed.Do you wish to continue?  If you want to update entire status column  as "Complete", Please Click "OK" to continue.</div>
    }
    useEffect(() => {
        if (selectedRow && selectedMember !== null) {
            selectedRow.setDataValue('assigneeName', selectedMember);
            setSelectedMember(null);
        }
    }, [selectedMember, selectedRow]);
    useEffect(() => {
        if (!selectedRow) {
            setSelectedMember(null);
        }
    }, [selectedRow]);
    return (
        <>
            <h1 className="searchtitle">Eligibility Errors and Warnings</h1>
            <div className="line"></div>
            <Box>
                <div className="ag-theme-alpine error-trends">
                    <div className="results-buttons">
                        <div className="left-buttons">
                            <button className="edit-columns"
                                onClick={toggleColumnMenu}
                            >
                                Edit Columns
                         </button>
                            <button className="mtd-lists"
                                onClick={handleClick}
                            >
                                {selectedName}
                            </button>
                            {dropDownVisible &&
                                <ul className="dropdown">
                                    {MTD_ITEM.map((item, index) => (
                                        <li key={index} className="mtd-dropdown-li-ob" onClick={() => handleItemClick(item)}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                        <div>
                            <IconButton className="assign-to-me" onClick={() => handlePopupOpen('assign')} disabled={assignButtonDisabled || isAssigned}>
                                <p>(Re)-Assign all to me </p>
                                <Icon name="PermIdentityOutlinedIcon" className="arrow-icon" />
                            </IconButton>
                            <IconButton className="download-results"
                                onClick={() => handlePopupOpen('complete')}
                                disabled={isButtonDisabled || isCompleted}>
                                <p>Mark all as complete </p>
                                <Icon name="DoneIcon" className="arrow-icon" />
                            </IconButton>
                            <IconButton className="download-results" onClick={handleExportExcel}>
                                <p>Download Results </p>
                                <Icon name="ArrowDownwardIcon" className="arrow-icon" />
                            </IconButton>
                        </div>

                    </div>
                    {showColumnMenu && (
                        <div className="columns">

                            <strong> Columns</strong>
                            {columnDefs.map((column) => (
                                <div key={column.field} className="column-margin">
                                    <label className="checkbox-pointer">
                                        <input
                                            type="checkbox"
                                            checked={!column.hide}
                                            onChange={(e) => toggleColumn(column.field, e.target.checked)}
                                            disabled={column.headerName === 'Actions'}
                                            classNames="column-names"
                                        />
                                        {column.headerName}
                                    </label>
                                </div>
                            ))}
                            <div className="edit-columns-buttons">
                                <button onClick={handleReset} className="reset-button" >Reset</button>
                                <button onClick={handleApply} className="apply-button"> Apply </button>
                            </div>
                        </div>
                    )}
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        gridOptions={gridOptions}
                        onColumnMoved={onColumnMoved}
                        suppressDragLeaveHidesColumns={true}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 15, 20, 50]}
                        domLayout='autoHeight'
                        enableColumnHandling
                        ref={(grid) => { gridApiRef.current = grid }}
                    />
                    <CustomModal
                        open={openPopup}
                        handleClose={handlePopupClose}
                        title="Disclaimer"
                        content={content}
                        onConfirmComplete={actionHandler}
                        showButtons={setShowButtons}
                        modalPosition={{ top: '50%', left: '50%' }}
                        applyStyle="true"
                    />
                    <Model
                        open={viewModal}
                        title="Select any one  of the Actions:"
                        handleClose={handleViewModalClose}
                        onConfirmComplete={handleComplete}
                        viewList={viewOptions}
                        onItemClick={handleViewItemClick}
                        modalPosition={{ top: '50%', left: '50%' }}
                    />
                    <Model
                        open={membersModal}
                        title="Please select from following List"
                        handleClose={handleViewModalClose}
                        viewList={teamMembersList}
                        onItemClick={handleNameClick}
                        modalPosition={{ top: '50%', left: '50%' }}
                        positions={true}
                    />
                </div>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    errorResultsData: eligibilityErrorResultsAction,
    assignAllToMe: reAssignAllToMeAction,
    markAllAsComplete: markAsCompleteAction,
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrorResults);