import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import '../../../Assets/Css/FileTracking.css'
import { fileTrackingColumnDef } from "../../../Utils/Widget/PayerEligibilityFileTracking/fileTracking.util"
import { fileTrackingAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { connect } from "react-redux";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate, useLocation } from "react-router-dom";
import {
  TITLE,
  CLASS_NAME,
  PAGINATION,
  TEXT
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS, COMMON_TEXT, AG_COLUMN_CENTER } from "../../../Constants/constants"
import { ICON_CONSTANT } from "../../../Constants/iconsConstant"
import Icon from "../../Common/Icon"
import "../../../Assets/Css/Breadcrumbs.css"
import IconButton from '@mui/material/IconButton';
import { Box, Typography, Grid } from '@mui/material';
import FileStatusRender from "./FileStatusRender"
import GotoFileDetails from "./GotoFileDetails"
import FileTrackingDatePicker from "./FileTrackingDatePicker"
import dayjs from 'dayjs';
import {getMonth} from "../../../Utils/Common/common.util"

const Filetracking = (props) => {
  const { useState, useMemo, useEffect, useRef } = React
  const navigate = useNavigate();
  const location = useLocation();
  const { DOWNLOAD_EXCEL } = TEXT
  const [selectMonth, setSelectMonth] = useState(dayjs());
  const { getData, file_tracking: { records = [] } } = props
  const {
    SEARCH_TITLE,
    LINE,
    FILE_TRACKING_GRID_CLASS,
    DATE_PICKER_CONTAINER,
    DOWNLOAD_CONTAINER,
    FILE_TRACKING_RESULT
  } = CLASS_NAME
  const { AG_THEME_ALPINE, CURSOR_POINTER } = COMMON_CLASS
  const { CUSTOM } = COMMON_TEXT
  const { FILE_SEARCH } = ROUTES
  const { FILE_TRACKING } = TITLE
  const { INSERT_DRIVE_FILE_OUTLINED_ICON } = ICON_CONSTANT


  const gridRef = useRef(null);

  useEffect(() => {
    getData(
      {
        month: selectMonth.month() + 1,
        year: selectMonth.year(),
        page: 1,
        limit: 100
      }
    )
  }, [selectMonth])

  const goToFile = (param = '') => {
    navigate(FILE_SEARCH, { state: { from: location?.pathname, fileName: param } })
  }


  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      display: "flex",
      alignItems: "center",
      textAlign: "center"
    };
  }, []);
  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      groupSelects: "descendants",
      headerCheckbox: false,
      checkboxes: false,
    };
  }, []);
  const exportToExcel = () => {
    gridRef.current.api.exportDataAsExcel({
      fileName: 'FileTrackingData.xlsx',
    });
  };
  return (
    <>
      <h1 className={`${SEARCH_TITLE}`}>{FILE_TRACKING}</h1>
      <div className={`${LINE}`}></div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <FileTrackingDatePicker
              value={selectMonth}
              getValue={setSelectMonth}
              label={`Select Month`}
              minDate={dayjs().subtract(1, 'year')}
              views={['month', 'year']}
              containerClass={DATE_PICKER_CONTAINER} />
            <p className={`${FILE_TRACKING_RESULT}`}>
              {`Showing 
              ${location?.state?.payerName ? 
                records.filter(record => location?.state?.payerName === record.payerName)?.length : 
                records?.length} for ${getMonth(selectMonth.month())}, ${selectMonth.year()}
              `}
            </p>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={exportToExcel}
              className={`${DOWNLOAD_CONTAINER}`}
              sx={
                {
                  color: 'blue',
                  fontSize: 15,
                  border: 'none',
                  outline: 'none',
                  background: 'none',
                  borderRadius: 0
                }
              }>
              <Typography variant="body2" sx={{ margin: 0 }}>{DOWNLOAD_EXCEL}</Typography>
              <Icon name="ArrowDownwardIcon" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <div className={`${AG_THEME_ALPINE} ${FILE_TRACKING_GRID_CLASS}`}>
        <AgGridReact
          ref={gridRef}
          rowData={location?.state?.payerName ? records.filter(record => location?.state?.payerName === record.payerName) : records}
          columnDefs={fileTrackingColumnDef({
            AG_COLUMN_CENTER,
            FileStatusRender,
            GotoFileDetails,
            records,
            goToFile,
            INSERT_DRIVE_FILE_OUTLINED_ICON,
            CURSOR_POINTER
          })}
          defaultColDef={defaultColDef}
          groupDisplayType={CUSTOM}
          groupDefaultExpanded={0}
          rowSelection={rowSelection}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={PAGINATION}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { file_tracking } = state
  return { file_tracking };
};

const mapActionToProps = {
  getData: fileTrackingAction
};

export default connect(mapStateToProps, mapActionToProps)(Filetracking);
