import React, {useState, useEffect} from 'react';
import axios from 'axios';

import { Routes, Route, useNavigate } from 'react-router-dom';
// import { OKTA_SCOPES } from '../Constants/constants';
import { ROUTES, DENIED_PATH } from '../Constants/routesConstant';

// import { Security, LoginCallback } from '@okta/okta-react';
// import { toRelativeUrl, OktaAuth } from "@okta/okta-auth-js";
import { RequiredAuth } from './SecureRoutes';

import Header from './Common/Header';
import Footer from './Common/Footer';
import SubscriberDetails from './Membership/Details/SubscriberDetails';
import Dashboard from './Dashboard/Dashboard';
import CheckAppInactivity from './Common/CheckAppInactivity';
import AdminAndSecurity from './AdminAndSecurity/AdminAndSecurity';


import SearchResults from './Membership/SearchResults/SearchResults';
import Eligibilityerrortrends from './Widget/EligibilityErrorsTrends/EligibilityErrorTrends';
import EligibilityErrorResults from './Widget/MembershipToErrorRatio/EligibilityErrorResults';
import FileTracking from './Widget/PayerEligibilityFileTracking/FileTracking';

import InputFileSearchResults from './Widget/PayerFileSearch/InputFileSearchResults';
import ErrorDetails from './Widget/MembershipToErrorRatio/ErrorDetails';
import AccessDenied from './Common/AccessDenied';
import NotFound from './Common/NotFound';

// const oktaAuth = new OktaAuth({
//   issuer: process.env.REACT_APP_ISSUER,
//   redirectUri: `${window.location.origin}${process.env.REACT_APP_CALLBACK}`,
//   clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
//   scopes: OKTA_SCOPES,
//   pkce: true,
//   disableHttpsCheck: true,
//   tokenManager: {storage: 'sessionStorage'}
//   // tokenManager: {storage: 'memory', autoRenew: true}
// });

const AppRoutes = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log(document.cookie, ' document.cookie');
    // const sessionToken = 
    // console.log(sessionToken, ' sessionToken');
    // if(sessionToken) {
    //   const tokenValue = sessionToken.split('=')[1];
    //   if(tokenValue){
    //     setAuthenticated(true);
    //     setLoading(false);
    //     return;
    //   }
    // }
    axios.get('https://api.payersolutions-nonprod-dev.aws.evernorthcloud.com/api/v1/admin/isAdmin', {
      withCredentials: true,
    }).then(response => {
      console.log('line 49 ', response);
      if(response.status === 200) {
        setAuthenticated(true);
      } else {
        window.location.href = 'https://api.payersolutions-nonprod-dev.aws.evernorthcloud.com/loginInitiate'
      }
    }).catch(error => {
      console.error('Authentication check failed: ', error);
      window.location.href = 'https://api.payersolutions-nonprod-dev.aws.evernorthcloud.com/loginInitiate'
    }).finally(() => {
      setLoading(false);
    })
  }, []);
  // const navigate = useNavigate();
  const {
    DASHBOARD,
    SUBSCRIBER,
    // AUTHORIZATION_CALLBACK,
    ADMIN_SECURITY,
    ERROR_TRENDS,
    ERROR_RESULTS,
    MY_ERROR_RESULTS,
    FILE_TRACKING,
    NOT_FOUND,
    ACCESS_DENIED,
    FILE_SEARCH,
    ERROR_DETAILS
  } = ROUTES;
  // const restoreOriginalUri = (_oktaAuth, originalUri) => {
  //   navigate(toRelativeUrl(originalUri || DASHBOARD, window.location.origin));
  // };
  if(loading) {
    return <div>Loading auth status</div>;
  }

  if(!authenticated) {
    return <div>Redirecting to login</div>
  }
  return (
    <div className="App">
      {/* <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
      > */}
        <Header />
        <CheckAppInactivity />
        <Routes>
          {/* <Route path={DASHBOARD} element={<RequiredAuth />}> */}
            <Route path="" element={!DENIED_PATH.includes(DASHBOARD) ? <Dashboard /> : <AccessDenied />} />
          {/* </Route> */}
          {/* <Route path={AUTHORIZATION_CALLBACK} element={<LoginCallback />} /> */}
          {/* <Route path={SUBSCRIBER.SUBSCRIBER_DETAILS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(SUBSCRIBER.SUBSCRIBER_DETAILS) ? <SubscriberDetails /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={SUBSCRIBER.DEPENDENT_DETAILS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(SUBSCRIBER.DEPENDENT_DETAILS) ? <SubscriberDetails /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={SUBSCRIBER.SEARCH_RESULT} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(SUBSCRIBER.SEARCH_RESULT) ? <SearchResults /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={ADMIN_SECURITY} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(ADMIN_SECURITY) ? <AdminAndSecurity /> : <AccessDenied />} />
          {/* </Route> */}
          {/* <Route path={ERROR_TRENDS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(ERROR_TRENDS) ? <Eligibilityerrortrends /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={ERROR_RESULTS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(ERROR_RESULTS) ? <EligibilityErrorResults /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={MY_ERROR_RESULTS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(ERROR_RESULTS) ? <EligibilityErrorResults /> : <AccessDenied />}
            />
          {/* </Route> */}

          {/* <Route path={FILE_SEARCH} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(FILE_SEARCH) ? <InputFileSearchResults /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={FILE_TRACKING} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(FILE_TRACKING) ? <FileTracking /> : <AccessDenied />}
            />
          {/* </Route> */}
          {/* <Route path={ERROR_DETAILS} element={<RequiredAuth />}> */}
            <Route
              path=""
              element={!DENIED_PATH.includes(ERROR_DETAILS) ? <ErrorDetails /> : <AccessDenied />}
            />
          {/* </Route> */}
          <Route
            path={NOT_FOUND}
            element={<NotFound />}
          />
          <Route
            path={ACCESS_DENIED}
            element={<AccessDenied />}
          />
        </Routes>
        <Footer />
      {/* </Security> */}
    </div>
  );
};

export default AppRoutes;