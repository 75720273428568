import { MARK_AS_COMPLETE } from '../../../Constants/actionTypes';

export const markAsCompleteReducer = (state = null, action) => {
  switch (action.type) {
    case MARK_AS_COMPLETE:
      return action.payload;
    default:
      return state;
  }
};
