import { CHECK_USER_IS_ADMIN } from '../../../Constants/actionTypes';

export const checkUserIsAdminReducer = (state = null, action) => {
  switch (action.type) {
    case CHECK_USER_IS_ADMIN:
      return action?.payload || {};
    default:
      return state;
  }
};
