import { ROUTES } from '../routesConstant';
export const DASHBOARD_TEXT = {
  TITLE: 'Dashboard',
  ADD_WIDGET: 'Add Widget',
  SAVE_DASHBOARD: 'Save Dashboard',
  EDIT_DASHBOARD: 'Edit Dashboard',
  RESET_DASHBOARD: 'Reset Dashboard',
};

export const HEADER_TEXT = {
  REFRESH: 'Refresh Page',
  RETURN_TO_DASHBOARD: 'Return to Dashboard',
  SELECTED_USER: {
    PRIMARY: 'primary',
    DEPENDENT: 'dependent',
  },
};

export const MORE_ACTION = {
  TEXT: 'More Action ...',
  MORE_ACTIONS_ROUTES: [
    ROUTES.SUBSCRIBER.SUBSCRIBER_DETAILS,
    ROUTES.SUBSCRIBER.DEPENDENT_DETAILS,
  ],
  MENU: {
    SHOW_DEPENDENT: 'Show dependents',
    SHOW_PRIMARY_SUBSCRIBER: 'Show subscriber',
    VIEW_PRODUCT: 'View  products',
    VIEW_TRACKING_HISTORY: 'View tracking history',
    VIEW_AUDIT_HISTORY: 'View audit history',
    VIEW_ACCOUNT_NOTES: 'View account notes',
    VIEW_COMPLIANCE_INFORMATION: 'View compliance information',
    ADD_DEPENDENT: 'Add dependent',
    EDIT: 'Edit',
    ADD_DEPENDENT_AND_EDIT: 'Add dependent and Edit',
  },
};

export const MEMBERSHIP_SEARCH = {
  TITLE: 'Eligibility Search Results',
};

export const ADMIN_SECURITY = {
  TABS_HEADER: {
    ADMIN_SETTINGS: 'Admin Settings',
    SECURITY_SETTINGS: 'Security Settings',
  },
  ADMIN_SETTINGS: {
    ADD_ROW: 'Add Row',
  },
  SECURITY_SETTINGS: {
    TAG: 'Tag',
    Add_TPA_ID: 'Add TPA ID',
  },
};

export const MEMBERSHIP_DEPENDENTS_MODAL = {
  TITLE: {
    DEPENDENTS: 'Dependents',
  },
};
export const MEMBERSHIP_TRACKING_MODAL = {
  TITLE: {
    TRACKING: 'Transaction audit history',
  },
};
