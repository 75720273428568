import { REASSIGN_ALL_TO_ME } from '../../../Constants/actionTypes';

export const reAssignAllToMeReducer = (state = null, action) => {
  switch (action.type) {
    case REASSIGN_ALL_TO_ME:
      return action.payload;
    default:
      return state;
  }
};
