import { ERROR_RESULTS_MTD } from '../../../Constants/actionTypes';

export const errorResultsMTDReducer = (state = null, action) => {
  switch (action.type) {
    case ERROR_RESULTS_MTD:
      return action.payload;
    default:
      return state;
  }
};
