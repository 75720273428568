import React from "react"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const FileTrackingDatePicker = (props) => {
  const {
    containerClass,
    label,
    views,
    value,
    getValue,
    minDate
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          value={value}
          onChange={(newValue) => getValue(newValue)}
          className={`${containerClass}`}
          label={label}
          minDate={minDate}
          disableFuture
          views={views} />
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default FileTrackingDatePicker