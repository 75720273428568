import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BREADCRUMB_CONSTANT } from '../../Constants/breadCrumbConstant';
import { Grid, Typography } from "@mui/material";
import "../../Assets/Css/Breadcrumbs.css";
import { ROUTES, File } from "../../../src/Constants/routesConstant"

const Breadcrumbs = () => {
  const [pageBreadcrumbs, setPageBreadcrumbs] = React.useState([]);
  const location = useLocation();
  const { ERROR_DETAILS } = ROUTES
  const { pathname } = location;
   React.useEffect(() => {
    const breadCrumbConst = BREADCRUMB_CONSTANT?.find(e => {
      return e.PATH === pathname || (e.PATH.includes(':id') && pathname.startsWith(ERROR_DETAILS.replace(':id', '')));
    });
    if (breadCrumbConst) {
      const { BREADCRUMB = [] } = breadCrumbConst;
      setPageBreadcrumbs(BREADCRUMB);
    } else {
      setPageBreadcrumbs([]);
    }
  }, [location]);
  let dynamicClass = '';

  switch (location.pathname) {
    case ROUTES.SUBSCRIBER.SEARCH_RESULT:
      dynamicClass = 'MembershipSearchResults';
      break;
    case ROUTES.FILE_TRACKING:
      dynamicClass = 'FileTracking';
      break;
    case ROUTES.ERROR_TRENDS:
      dynamicClass = 'EligibilityErrorTrends';
      break;
    case ROUTES.ADMIN_SECURITY:
      dynamicClass = 'AdminSecuritySetup';
      break;
    case ROUTES.FILE_SEARCH:
      dynamicClass = 'FileSearchResults';
      break;
    case ROUTES.MY_ERROR_RESULTS:
      dynamicClass = 'EligibilityErrorsAndWarnings';
      break;
    case ROUTES.ERROR_RESULTS:
      dynamicClass = 'EligibilityErrorsAndWarning';
      break;
    case ROUTES.ERROR_DETAILS:
      dynamicClass = 'ErrorDetails';
      break;


  }

  '';
  return (
    <Grid container className="container" spacing={1}>
      <Grid item xs={12}>

        <div className="spacer"></div>
        <div className={`breadcrumbWrapper ${dynamicClass}`}>
          {
            pageBreadcrumbs.length > 0 &&
            pageBreadcrumbs?.map(({ page_name, path }, i) => (
              <Typography key={page_name} component="span">
                {i > 0 && ' > '}
                {
                  pageBreadcrumbs?.length - 1 === i ? <>{page_name}</> : <Link to={path} className="styledLink">{page_name}</Link>
                }
              </Typography>
            ))

          }
        </div>
      </Grid>
    </Grid>
  );
};

export default Breadcrumbs;
